import { Controller } from 'stimulus';
import { Notify } from 'notiflix';
import axios from 'axios';

export default class extends Controller {
  connect() {
    let stripePubID = document.querySelector('meta[name="stripe-public-key"]').getAttribute('content');
    this.stripe = Stripe(stripePubID)
  }

  redirectToStripe(ev) {
    ev.preventDefault();
    axios.get(this.urlValue).then(response => {
      let { data } = response;
      this.stripe
          .redirectToCheckout({
            sessionId: data.sessionId
          })
          .then(this.handleResult);
    }).catch(() => {
      Notify.Failure('Trenutno storitev ni na voljo! Prosim poskusite kasneje')
    })
  }

  get urlValue() {
    if (this.element.dataset.url) {
      return this.element.dataset.url;
    } else {
      console.error('Subscription URL must be set!')
    }
  }

  handleResult(result) {
    if (result.error) {
      Notify.Failure(result.error.message)
    }
  }
}
