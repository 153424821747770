// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require('@rails/ujs').start()
import '../tools/swal_confirm'
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('trix')
require('@rails/actiontext')

// Tailwind CSS
import '@fortawesome/fontawesome-free/js/all'
import '../stylesheets/application.scss'
import 'sweetalert2/src/sweetalert2.scss'
import 'trix/dist/trix.css'
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'controllers'
require("trix")
require("@rails/actiontext")

import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min';
import 'tinymce/skins/ui/oxide/content.min';
import 'tinymce/icons/default';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import '../main/notify';

require.context(
    '!file-loader?name=[path][name].[ext]&context=node_modules/tinymce&outputPath=js!tinymce/skins',
    true,
    /.*/
);
document.addEventListener('turbolinks:load', function() {
  if (document.getElementById('tinyMCEeditor')) {
    tinymce.remove();

    tinymce.init({
      selector: '#tinyMCEeditor',
      convert_urls: false,
      plugins: ['paste', 'link', 'table', 'lists'],
      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat'
    });
  }
})