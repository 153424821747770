import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['menu']

  toggleDropdown() {
    if (this.menuTarget.classList.contains('invisible')) {
      this.menuTarget.classList.remove('invisible');
      this.menuTarget.classList.add('visible');
    } else {
      this.menuTarget.classList.add('invisible');
      this.menuTarget.classList.remove('visible');
    }
  }
}
