import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'value', 'input', 'actions'];

  showEdit(ev) {
    ev.preventDefault();
    this.formTarget.classList.remove('hidden');
    this.valueTarget.classList.add('hidden');
    this.actionsTarget.classList.add('hidden');
    this.inputTarget.focus();
  }

  cancel(ev) {
    ev.preventDefault();
    this.formTarget.classList.add('hidden');
    this.actionsTarget.classList.remove('hidden');
    this.valueTarget.classList.remove('hidden');
  }
}
