import Rails from '@rails/ujs';
import Swal from 'sweetalert2';

const confirmed = function(element, result) {
  if (result.value) {
    element.removeAttribute('data-confirm-swal')
    element.click()
  }
}

const showConfirmationDialog = function(element) {
  const message = element.getAttribute('data-confirm-swal')
  const text = element.getAttribute('data-text')
  const type = element.getAttribute('data-confirm-type')

  Swal.fire({
    title: message || 'Ali ste prepričani?',
    text: text || '',
    icon: type || 'warning',
    showCancelButton: true,
    confirmButtonColor: '#4299e1',
    cancelButtonColor: '#cbd5e0',
    confirmButtonText: 'DA',
    cancelButtonText: 'NE',
  }).then(result => confirmed(element, result))
}

const allowAction = function(element) {
  if (element.getAttribute('data-confirm-swal') === null) {
    return true
  }

  showConfirmationDialog(element)
  return false
}

const handleConfirm = function(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element)
  }
}

// Add event listener before the other Rails event listeners like the one
// for `method: :delete`
Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm)

Rails.start()