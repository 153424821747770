import { Notify } from 'notiflix';

Notify.Init(
    {
      success: {
        background: '#28a745',
        textColor:'#fff',
        childClassName:'success',
        notiflixIconColor:'rgba(0,0,0,0.2)',
        fontAwesomeClassName:'fas fa-check-circle',
        fontAwesomeIconColor:'rgba(0,0,0,0.2)',
        backOverlayColor:'rgba(50,198,130,0.2)'
      },
      failure: {
        background:'#dc3545',
        textColor:'#fff',
        childClassName:'failure',
        notiflixIconColor:'rgba(0,0,0,0.2)',
        fontAwesomeClassName:'fas fa-times-circle',
        fontAwesomeIconColor:'rgba(0,0,0,0.2)',
        backOverlayColor:'rgba(255,85,73,0.2)'
      },
      warning: {
        background:'#ffc107',
        textColor:'#fff',
        childClassName:'warning',
        notiflixIconColor:'rgba(0,0,0,0.2)',
        fontAwesomeClassName:'fas fa-exclamation-circle',
        fontAwesomeIconColor:'rgba(0,0,0,0.2)',
        backOverlayColor:'rgba(238,191,49,0.2)'
      },
      info: {
        background:'#17a2b8',
        textColor:'#fff',
        childClassName:'info',
        notiflixIconColor:'rgba(0,0,0,0.2)',
        fontAwesomeClassName:'fas fa-info-circle',
        fontAwesomeIconColor:'rgba(0,0,0,0.2)',
        backOverlayColor:'rgba(38,192,211,0.2)'}
    }
)

const showNotification = (element) => {
  switch (element.getAttribute('data-type')) {
    case 'success':
      Notify.Success(element.getAttribute('data-content'))
      break;
    case 'notice':
      Notify.Info(element.getAttribute('data-content'))
      break;
    case 'error':
      Notify.Failure(element.getAttribute('data-content'))
      break;
    case 'alert':
      Notify.Warning(element.getAttribute('data-content'))
      break;
  }
}

const alertListener = () => {
  let element = document.querySelector('meta[data-alert="true"]');

  if (element) {
    showNotification(element);
  }
}

document.addEventListener('turbolinks:load', alertListener);
