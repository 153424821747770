import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['section', 'carretUp', 'carretDown'];

  toggle(ev) {
    ev.preventDefault();
    if (this.sectionTarget.classList.contains('hidden')) {
      this.sectionTarget.classList.remove('hidden');
      this.carretUpTarget.classList.remove('hidden');
      this.carretDownTarget.classList.add('hidden');
    } else {
      this.sectionTarget.classList.add('hidden');
      this.carretUpTarget.classList.add('hidden');
      this.carretDownTarget.classList.remove('hidden');
    }
    ev.currentTarget.blur();
  }
}
